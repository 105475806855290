// See sister file: /Cypress/support/constants

export const DATE_FORMAT_DISPLAY = 'MM/DD/YYYY'
export const DATE_FORMAT_SERVER = 'YYYY-MM-DD'
export const PRACTITIONER_MAX_DAYS = 2000
export const NON_PRACTITIONER_MAX_DAYS = 28
export const REQUEST_LIST_FROM = 'request list'
export const LOCATION_OPEN_STATUS_OPEN = 'Open'
export const LANGUAGE_CODES = {
  ENGLISH: 'en',
  SPANISH: 'es',
}
export const HELPDESK_EMAIL = 'helpdesk@wksusa.com'

export enum ActionBoardUserType {
  Practitioner,
  HR,
}

export const TimePunchTemplateFileContents = `UseCaseNbr,FranchisorAbbr,PunchType,DateTimeIn,DateTimeOut,EmployeeName,EmployeeId,HomeStoreName,WorkedStoreName,WorkedSt
Example1,WEN,Shift,3/8/2022 8:00,3/8/2022 10:00,"Doe, Christopher",ESF001361,FRO-29950 San Diego - Midway,FRO-29790 San Diego - Convoy,CA
Example1,WEN,Break,3/8/2022 10:00,3/8/2022 10:30,"Doe, Christopher",ESF001361,FRO-29950 San Diego - Midway,FRO-29790 San Diego - Convoy,CA
Example1,WEN,Shift,3/8/2022 10:30,3/8/2022 14:00,"Doe, Christopher",ESF001361,FRO-29950 San Diego - Midway,FRO-29790 San Diego - Convoy,CA
Example1,WEN,Shift,3/9/2022 10:00,3/9/2022 14:00,"Doe, Christopher",ESF001361,FRO-29950 San Diego - Midway,FRO-29790 San Diego - Convoy,CA`

export const ssnLastFourRegex = /^\d{4}$/

export const xsrfTokenFormFieldName = 'XSRF-TOKEN'
export const xsrfTokenCookieName = 'XSRF-TOKEN'
export const xsrfTokenQueryName = 'XSRF-TOKEN'
export const xsrfHttpStatusCode = 419
export const xsrfUrlPath = 'xsrf-token'
