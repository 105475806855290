import * as Sentry from '@sentry/react'
import { DATE_FORMAT_SERVER, xsrfUrlPath } from 'appConstants'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { saveXsrfToken } from 'components/xsrf/XsrfTokenProvider'
import dayjs, { Dayjs } from 'dayjs'
import { isNil, omitBy } from 'lodash'
import { MutationFunction } from 'react-query'
import { BaseRequest } from 'types/BaseRequest'
import { BaseState } from 'types/BaseState'
import { BorrowRequest, BorrowState } from 'types/BorrowRequest'
import { CompleteState } from 'types/Complete'
import {
  ConfirmSsnLastFourRequest,
  ConfirmSsnLastFourResult,
  DocumentLinkRequest,
  DocumentSignLogoutRequest,
  EmployeeDocRequest,
  EsignDetailData,
  EsignEmployeeDocData,
  EsignListResponseBase,
  EsignMainDashboardData,
  GenerateDocumentsRequest,
  GenerateDocumentsResult,
  ParentOrGuardianMobileRequest,
  ParentOrGuardianRequest,
  StartEmailVerificationResult,
  SyncDocumentStatusRequest,
  SyncDocumentStatusResult,
  ValidateEmailRequest,
  ValidateEmailResult,
} from 'types/DocumentSign'
import {
  Employee,
  EmployeeSearch,
  JobTitle,
  SyncEmployeeFromADPRequest,
  SyncEmployeeInfo,
} from 'types/Employee'
import {
  HourlySalaryRequest,
  HourlySalaryState,
} from 'types/HourlySalaryRequest'
import { Issuer } from 'types/Issuer'
import {
  JobFunctionChangeRequest,
  JobFunctionChangeState,
  JobTitleChangeRequest,
  JobTitleChangeState,
} from 'types/JobChangeRequest'
import {
  LeaveOfAbsenceRequest,
  LeaveOfAbsenceState,
  hasLeaveOfAbsenceDocumentKeys,
} from 'types/LeaveOfAbsenceRequest'
import { Location, TransferLocation } from 'types/Location'
import {
  AlohaPosJobCode,
  POSJobCodeRequest,
  POSJobCodeState,
} from 'types/POSJobCodeRequest'
import { PositionStatus } from 'types/PositionStatus'
import { PABData } from 'types/PractitionerActionBoard'
import { RateChangeRequest, RateChangeState } from 'types/RateChangeRequest'
import { RateUnit } from 'types/RateUnit'
import { RehireRequest, RehireState } from 'types/RehireRequest'
import {
  ReturnFromLeaveRequest,
  ReturnFromLeaveState,
} from 'types/ReturnFromLeaveRequest'
import {
  StatusChangeList,
  StatusChangeResponseBase,
} from 'types/StatusChangeResponse'
import {
  AlignmentChange,
  LeaderEmployee,
  MgmtEmployee,
  StoreAlignments,
} from 'types/StoreAlignment'
import {
  TerminationChangeRequest,
  TerminationChangeState,
  WorkedDay,
  readyToSubmit,
} from 'types/TerminationChangeRequest'
import { TransferRequest, TransferState } from 'types/TransferRequest'
import { UploadConfig } from 'types/UploadConfig'
import { UploadResult } from 'types/UploadResult'
import { UploadedFile } from 'types/UploadedFile'
import {
  EplToSageAccount,
  EplToSageAccountRow,
  brandItemGlCode,
  dcItemNumber,
} from 'types/accounting/DmaDelivers'
import {
  KkdKorberToSageListResponse,
  KkdKorberToSageMapData,
} from 'types/accounting/KkdKorber'
import { isXsrfError, stripUndefinedParams } from 'utils'
import {
  CoDeptCollection,
  ITimePunchUIFilter,
  PosToWksMapping,
  ReportEndpointTypes,
  StoreEmployee,
  TimePunchAdpExportResult,
  TimePunchEdit,
  TimePunchValidationErrors,
  UpdateTimePunchLockedRequest,
} from './types/TimePunchTypes'

export const BASE_URL = process.env.REACT_APP_API_URL
export const isXsrfProtectionEnabled =
  process.env.REACT_APP_ENABLE_XSRF === 'true'

const isBasicAuthActive =
  process.env.NODE_ENV !== 'production' &&
  process.env.REACT_APP_USE_BASIC_AUTH === 'true'

const api = axios.create({
  baseURL: `${BASE_URL}/api/`,
  withCredentials: !isBasicAuthActive,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  ...(isBasicAuthActive && {
    auth: {
      username: process.env.REACT_APP_BASIC_AUTH_USERNAME || '',
      password: process.env.REACT_APP_BASIC_AUTH_PASSWORD || '',
    },
  }),
  timeout: 0,
})

api.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (
      isXsrfError(error) &&
      error.config.url !== `${api.defaults.baseURL}${xsrfUrlPath}`
    ) {
      const token = await getXsrfToken()
      saveXsrfToken(token)
    }
    return Promise.reject(error)
  }
)

// https://github.com/softonic/axios-retry/issues/87
const retryDelay = (retryNumber = 0) => {
  const seconds = Math.pow(2, retryNumber) * 1000
  const randomMs = 1000 * Math.random()
  return seconds + randomMs
}

const retryCount =
  {
    dev: 1,
    local: 1,
  }[process.env.REACT_APP_ENV_TARGET ?? ''] || 3

axiosRetry(api, {
  retries: retryCount,
  retryDelay,
  retryCondition: axiosRetry.isNetworkOrIdempotentRequestError,
})

export const getAdpLoginUrl = (returnUrl: string) =>
  `${BASE_URL}/api/login${returnUrl && `?returnUrl=${returnUrl}`}`

export const getGoogleLoginUrl = (returnUrl: string) =>
  `${BASE_URL}/api/login-google${returnUrl && `?returnUrl=${returnUrl}`}`

// function that adds interceptor for requests after login
// We want to be able to show a login/landing page instead of just redirecting immediately,
// but once we are logged in, we want to add this interceptor. This function called on successful login
export const addApiInterceptors = () => {
  const contactHelpDesk =
    ' Please try again. If this continues to happen, please contact WKS Help Desk at helpdesk@wksusa.com.'
  // Auth interceptor
  api.interceptors.response.use(
    (res) => res,
    async (error) => {
      if (error.response && error.response.status === 401) {
        window.location.reload() // Reload to reset authentication and display sign in screen
      } else if (error.response) {
        // Handle blob https://github.com/axios/axios/issues/815#issuecomment-453963910
        let data: any
        if (
          error.request.responseType === 'blob' &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
          const readerPromise = new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
              error.response.data =
                typeof reader.result == 'string' && JSON.parse(reader.result)
              resolve(Promise.reject(error))
            }

            reader.onerror = () => {
              reject(error)
            }

            reader.readAsText(error.response.data)
          })
          data = await readerPromise
        } else {
          data = error.response.data
        }
        if ((data?.errors?.length ?? 0) > 0) {
          error.message = data?.errors[0].detail
          throw error
        } else if (data?.errors && Object.keys(data.errors)?.length > 0) {
          error.message = Object.entries(data.errors)
            .map(([key, value]) => `${key} - ${value}`)
            .join(', ')
          throw error
        } else {
          Sentry.captureMessage(
            `Cannot read error object ${JSON.stringify(data)}`
          )
          error.message =
            'An error has occurred.  Unable to read response.' + contactHelpDesk
          throw error
        }
      } else if (
        error.message &&
        error.message.toLowerCase().includes('network error')
      ) {
        error.message = 'Network error.' + contactHelpDesk
        throw error
      }
      throw error
    }
  )
}

export interface ListResponseMeta {
  page: number
  totalPages: number
  recordsPerPage: number
  totalRecords: number
}

export interface StatusChangeListResponseMeta extends ListResponseMeta {
  allowedValues?: {
    availableStatuses: string[]
  }
}

export interface EsignListResponseMeta extends ListResponseMeta {
  allowedValues?: {
    availableStatuses: string[]
    availableAdpStatuses: {
      [key: string]: string
    }
  }
  isDetailCsvDownloadEnabled: boolean
  isCsvDownloadEnabled: boolean
}

export interface AxiosError<R = unknown, M = void> {
  response?: {
    data?: APIResult<R, M>
  }
  message: string
}

export interface APIResult<R, M = void> {
  data: R
  meta?: M
  errors: Array<IApiError>
}

export interface FileContentResult {
  contentType: string
  enableRangeProcessing: boolean
  entityTag: string | null
  fileContents: string
  fileDownloadName: string
  lastModified: string | null
}

/**
 * AUTHENTICATION
 */
export enum NoAccessPageType {
  NoAccessToThisApplication = 1,
  PleaseLoginWithAdp = 2,
}

export interface IsAuthenticatedResponse {
  name: string
  employeeId: number
  associateId: string
  roles: string[]
  issuer: Issuer
  isImpersonating: boolean
  locationName: string
  discountCode: string
  primaryFranchisorAbbr: string
  noAccessPageType: NoAccessPageType | null
  supportNotes: string | null
}

export const getIsAuthenticated = async () => {
  return api.get<APIResult<IsAuthenticatedResponse>>('is-authenticated')
}

export interface IsAuthenticatedDiscountResponse {
  name: string
  employeeId: number
  associateId: string
  locationName: string
  discountCode: string
}

export const getIsAuthenticatedDiscount = async () => {
  return api.get<APIResult<IsAuthenticatedResponse>>(
    'is-authenticated-discount'
  )
}

export const getXsrfToken = async () => {
  return api.get<APIResult<string>>(xsrfUrlPath)
}

/**
 * DOWNLOAD ROSTER CORRECTION CSV
 */
export const getRosterCorrectionCsv = async (companyCode: string) => {
  const { data } = await api.get(
    `employees/roster-correction-file-download?companyCode=${companyCode}`
  )
  return data
}

/**
 * DOWNLOAD REQUESTS CSV
 */
export const getCSV = async (url, query, sortVal, sortByDirection) => {
  const params = new URLSearchParams({
    ...query,
    ...(sortVal !== undefined ? { sortBy: sortVal } : {}),
    ...(sortByDirection !== undefined ? { sortByDirection } : {}),
  })
  const buildQuery: string[] = []
  const entries = Array.from(params.entries())

  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i]
    if (value && value !== 'undefined') {
      buildQuery.push(`${key}=${value}`)
    }
  }

  if (sortVal !== undefined) buildQuery.push(`sortBy=${sortVal}`)
  if (sortByDirection !== undefined) {
    buildQuery.push(`sortByDirection=${sortByDirection}`)
  }

  const uniq = Array.from(new Set(buildQuery))
  const urlParams = uniq.join('&')
  const { data } = await api.get(`${url}?${urlParams}`)
  return data
}

/**
 * REQUEST MANAGEMENT
 */
type RequestResponse = APIResult<
  Array<StatusChangeList>,
  StatusChangeListResponseMeta
>
type Query = {
  location?: string | null
  employeeName?: string | null
  requestorName?: string | null
  requestType?: string | null
  requestStatus?: string | null
}
export const getPABDetails = async (actionState, actionBoardUserType) => {
  if (!actionState) return null
  const { data } = await api.get<PABData>(
    `/requests/action-board?payrollWeekOption=${actionState}&actionBoardUserType=${actionBoardUserType}`
  )
  return data
}

export const getHrActionBoardDetails = async (actionBoardUserType) => {
  if (!actionBoardUserType) return null
  const { data } = await api.get<PABData>(
    `/requests/action-board?actionBoardUserType=${actionBoardUserType}`
  )
  return data
}

export const getRequests = async (
  page = 1,
  perPage = 10,
  query: Query,
  sortVal?: string | null,
  sortByDirection?: string | null
) => {
  const params = new URLSearchParams({
    page: page?.toString(), // MUI table has zero based page #
    perPage: perPage?.toString(),
    ...(sortVal && { sortBy: sortVal }),
    ...(sortVal && sortByDirection && { sortByDirection }),
    ...(query && { ...omitBy(query, isNil) }),
  })
  const { data } = await api.get<RequestResponse>(
    `requests/search?${params.toString()}`
  )
  return data
}

type EsignListResponse = APIResult<
  Array<EsignListResponseBase>,
  EsignListResponseMeta
>
export const getEsignList = async (
  page = 1,
  perPage = 10,
  query: Query,
  sortVal?: string | null,
  sortByDirection?: string | null
) => {
  const params = new URLSearchParams({
    page: page?.toString(), // MUI table has zero based page #
    perPage: perPage?.toString(),
    ...(sortVal && { sortBy: sortVal }),
    ...(sortVal && sortByDirection && { sortByDirection }),
    ...(query && { ...omitBy(query, isNil) }),
  })
  const { data } = await api.get<EsignListResponse>(
    `document-sign/search?${params.toString()}`
  )
  return data
}

export const getRequestDetail = async (id: string) => {
  const { data } = await api.get<APIResult<StatusChangeResponseBase>>(
    `requests/${id}`
  )
  return data
}

export const postRequestReject: MutationFunction<
  APIResult<[]>,
  { id: number; reason: string }
> = async ({ id, reason }) => {
  return api.post(`requests/${id}/reject`, {
    data: { rejectionReason: reason },
  })
}

export const postRequestApprove: MutationFunction<
  APIResult<[]>,
  number
> = async (id) => {
  return api.post(`requests/${id}/approve`)
}

export const postRequestCompleteManuallyInPOS: MutationFunction<
  APIResult<[]>,
  number
> = async (id) => {
  return api.post(`requests/${id}/completeManuallyInPOS`)
}

export const postGroupApprove: MutationFunction<APIResult<[]>, number> = async (
  id
) => {
  return api.post(`requests/${id}/groupApprove`)
}

export const postRequestComplete: MutationFunction<
  APIResult<[]>,
  Required<CompleteState>
> = async ({ id, pendingRequestsInAdpOnComplete }) => {
  return api.post(`requests/${id}/complete`, {
    data: { pendingRequestsInAdpOnComplete: pendingRequestsInAdpOnComplete },
  })
}

export const postRequestSaveToADP: MutationFunction<
  APIResult<[]>,
  Required<CompleteState>
> = async (state) => {
  return api.post(`requests/${state.id}/saveToADP`, {
    data: {
      pendingRequestsInAdpOnComplete: state.pendingRequestsInAdpOnComplete,
    },
  })
}

export const postRequestCancel: MutationFunction<
  APIResult<[]>,
  number
> = async (id) => {
  return api.post(`requests/${id}/cancel`)
}

/**
 * EMPLOYEE
 */
export const searchEmployee = async (
  positionStatuses?: PositionStatus[],
  employeeRecordRequestType?: string,
  isPrimaryPosition?: boolean | null,
  ssnForRehire?: string,
  name?: string
) => {
  const statuses = positionStatuses
    ? positionStatuses?.map((status) => `&status=${status}`).join('')
    : ''
  const type = employeeRecordRequestType
    ? `&employeeRecordRequestType=${employeeRecordRequestType}`
    : ''

  const isPrimary = isPrimaryPosition === null ? '' : '&isPrimaryPosition=true'

  const ssnForRehireParam = ssnForRehire ? `&ssnForRehire=${ssnForRehire}` : ''

  const nameParam = name ? `&name=${name}` : ''

  const { data } = await api.get<APIResult<Array<EmployeeSearch>>>(
    `employees/search?q=*${statuses}${type}${isPrimary}${ssnForRehireParam}${nameParam}`
  )
  return data
}

export const getChangeApprovers = async (
  q: string,
  requestId: number | undefined,
  positionStatuses?: PositionStatus[]
) => {
  const query = q || '*'
  const { data } = await api.get<APIResult<Array<EmployeeSearch>>>(
    `/change-approver-search?q=${query}&requestId=${requestId}`
  )
  return data
}
export interface EditDataRequest {
  employeeId: number | undefined
  recordId: number | undefined
  approver?: EmployeeSearch | null
  reason?: Reason | null
  effectiveDate?: Date | null
  notes?: string | null
}

export const isEditActiveApi: MutationFunction<any, EditDataRequest> = async (
  rcState
) => {
  const body = {
    employeeId: rcState.employeeId,
    recordId: rcState.recordId,
    approverId: rcState.approver?.id,
    reason: rcState.reason,
    effectiveDate: rcState.effectiveDate,
    notes: rcState.notes,
  }
  const { data } = await api.post<EditDataRequest>(`update-request`, body)
  return data
}
export const employeeDetail = async (
  id?: number,
  employeeRecordRequestType?: string
) => {
  const body = {
    employeeId: id,
    employeeRecordRequestType: employeeRecordRequestType,
  }

  const { data } = await api.post<APIResult<Employee>>(`employees`, {
    data: body,
  })
  return data
}

export const employeeDetailForRehire = async (
  id?: number,
  employeeRecordRequestType?: string,
  rehireSSN?: string,
  rehireFranchisorNames?: string[] | undefined
) => {
  const body = {
    employeeId: id,
    employeeRecordRequestType: employeeRecordRequestType,
    rehireSSN: rehireSSN,
    rehireFranchisorNames: rehireFranchisorNames,
  }

  const { data } = await api.post<APIResult<Employee>>(`employees`, {
    data: body,
  })
  return data
}

export interface POSJobCode {
  id: number
  franchisorAbbr: string
  alohaPosJobCodeId: number
  longName: string
}

export const getPOSJobCodes = async (
  franchisorAbbr: string | undefined,
  primaryAlohaJobCodeId: number | undefined
) => {
  const params = new URLSearchParams(
    stripUndefinedParams({
      franchisorAbbr,
      primaryAlohaJobCodeId: primaryAlohaJobCodeId?.toString(),
    })
  )

  const { data } = await api.get<APIResult<POSJobCode[]>>(
    `pos/job-codes?${params.toString()}`
  )
  return data
}

export interface Reason {
  code: string
  description: string
  isVoluntary?: boolean
  isRehireable?: boolean
  id?: number | undefined // making this optional for time being we refactor reason code all over app
}

export interface AdpJobCode {
  code: string
  description: string
}

export const getReasonCodes = async (
  id: number | undefined,
  type: string,
  jobTitleChange: Reason | undefined,
  effectiveDateLOA?: Date | null,
  filterReasonId?: number | undefined
) => {
  if (!id) return null

  type = type === 'LeaveOfAbsence' ? 'leave-of-absence' : type
  const Date = effectiveDateLOA
    ? dayjs(effectiveDateLOA).format('YYYY-MM-DD')
    : ''

  const { data } = await api.get<APIResult<Array<Reason>>>(
    `${type}/reason-codes?employeeId=${id ?? ''}${
      effectiveDateLOA ? `&effectiveDateLOA=${Date}` : ''
    }${filterReasonId ? `&filterReasonId=${filterReasonId}` : ''}`
  )
  if (type === 'rate-change' && jobTitleChange) {
    data.data.unshift(jobTitleChange)
    data.data.sort((a, b) => a.description.localeCompare(b.description))
  }
  return data
}

export const getJobTitles = async (
  id: number | undefined,
  adpCompany: string | undefined
) => {
  const { data } = await api.get<APIResult<Array<JobTitle>>>(
    `rehire/job-codes?employeeId=${id}&adpCo=${adpCompany}`
  )
  return data
}

export const getPrimaryJobCodes = async (
  id: number | undefined,
  type: string
) => {
  if (!id) return null
  const { data } = await api.get<APIResult<Array<AdpJobCode>>>(
    `${type}/job-codes?employeeId=${id}`
  )
  return data
}

export interface RequestResult {
  id: number
}

function mapSharedState(rcState: Required<BaseState>): BaseRequest {
  return {
    notes: rcState.notes,
    userAcknowledgedPendingRequestsInAdp:
      rcState.userAcknowledgedPendingRequestsInAdp,
    pendingRequestsInAdpOnSubmit:
      rcState.employee?.employeePendingActionsInAdp ?? [],
  }
}

type RateChangeResult = APIResult<RateChangeRequest & RequestResult>
export const postRateChange: MutationFunction<
  RateChangeResult,
  Required<RateChangeState>
> = async (rcState) => {
  // transform data for request
  const body: RateChangeRequest = {
    employeeId: rcState.employee.id,
    effectiveDate: dayjs(rcState.effectiveDate as Date).format('YYYY-MM-DD'),
    rate: rcState.rate,
    reasonCode: rcState.reasonCode?.code,
    adpJobFunctionCode: rcState.jobFunction ? rcState.jobFunction.jobCode : '',
    ...mapSharedState(rcState),
  }
  const { data } = await api.post<RateChangeResult>(`rate-change/submit`, {
    data: body,
  })
  return data
}

type HourlySalaryResult = APIResult<HourlySalaryRequest & RequestResult>
export const postHourlySalary: MutationFunction<
  HourlySalaryResult,
  Required<HourlySalaryState>
> = async (rcState) => {
  // transform data for request
  const body: HourlySalaryRequest = {
    employeeId: rcState.employee?.id,
    effectiveDate: dayjs(rcState.effectiveDate as Date).format('YYYY-MM-DD'),
    rate: rcState.rate,
    rateUnit: rcState?.rateUnit,
    reasonCode: rcState?.reasonCode?.code,
    adpJobCode: rcState.adpJobCode.code,
    ...mapSharedState(rcState),
  }
  const { data } = await api.post<HourlySalaryResult>(`hourly-salary/submit`, {
    data: body,
  })
  return data
}

type LeaveofAbsenceResult = APIResult<LeaveOfAbsenceRequest & RequestResult>
export const postLeaveOfAbsence: MutationFunction<
  LeaveofAbsenceResult,
  Required<LeaveOfAbsenceState>
> = async (rcState) => {
  // transform data for request
  const body: LeaveOfAbsenceRequest = {
    employeeId: rcState.employee.id,
    lastDayWorked: dayjs(rcState.lastDayWorked as Date).format('YYYY-MM-DD'),
    effectiveDate: dayjs(rcState.effectiveDate as Date).format('YYYY-MM-DD'),
    returnDate: dayjs(rcState.returnDate as Date).format('YYYY-MM-DD'),
    reasonId: rcState.reasonCode.id as number,
    hasLeaveOfAbsenceDocument:
      rcState.hasLeaveOfAbsenceDocument === hasLeaveOfAbsenceDocumentKeys.YES
        ? true
        : false,
    ...(rcState.employee?.hasReportTos && {
      replacementEmployeeId: rcState.replacement.id,
    }),
    ...mapSharedState(rcState),
  }
  const { data } = await api.post<LeaveofAbsenceResult>(
    `leave-of-absence/submit`,
    {
      data: body,
    }
  )
  return data
}

type TerminationChangeResult = APIResult<
  TerminationChangeRequest & { id: number }
>
export const postTerminationChange: MutationFunction<
  TerminationChangeResult,
  Required<TerminationChangeState>
> = async (rcState) => {
  if (!readyToSubmit(rcState)) throw new Error('Data not ready to submit')
  const workedDays = rcState.workedDays?.reduce(
    (acc: WorkedDay[], option: WorkedDay) => {
      if (option.isEnabled) {
        const filtered = {
          date: dayjs(option.date as Date).format('YYYY-MM-DD'),
          regularHours: option.regularHours ?? 0,
          overTimeHours: option.overTimeHours ?? 0,
          isEnabled: option.isEnabled,
          isLastDay: option.isLastDay,
        }
        acc.push(filtered)
      }
      return acc
    },
    []
  )
  // transform data for request
  const body: TerminationChangeRequest = {
    employeeId: rcState.employee.id,
    effectiveDate: dayjs(rcState.effectiveDate as Date).format('YYYY-MM-DD'),
    lastDayWorked: dayjs(rcState.lastDayWorked as Date).format('YYYY-MM-DD'),
    reasonCode: rcState.reasonCode?.code,
    ...(rcState.employee?.hasReportTos && {
      replacementEmployeeId: rcState.replacement.id,
    }),
    ...(rcState.workedDays && {
      workedDays: workedDays,
    }),
    ...mapSharedState(rcState),
  }

  const { data } = await api.post<TerminationChangeResult>(
    `termination/submit`,
    {
      data: body,
    }
  )
  return data
}

// TODO: Not currently used - revisit as part of #367
export const getEarliestEffectiveDate = async (id, code) => {
  if (!id || !code) return null
  const { data } = await api.get(
    `/termination/earliest-effective-date?employeeId=${id}&reasonCode=${code}`
  )
  return data
}

export const getMaxReturnDate = async (
  employeeId: number | undefined,
  effectiveDate: Date | null,
  reasonId: number | undefined
) => {
  if (!employeeId || !effectiveDate || !reasonId) return null
  const Date = dayjs(effectiveDate).format('YYYY-MM-DD')
  const { data } = await api.get<APIResult<{ maxReturnDate: Date }>>(
    `/leave-of-absence/get-max-return-date?employeeId=${employeeId}&effectiveDate=${Date}&reasonId=${reasonId}`
  )
  return data
}
type BorrowResult = APIResult<BorrowRequest & RequestResult>
export const postBorrow: MutationFunction<
  BorrowResult,
  Required<BorrowState>
> = async (requestState) => {
  // transform data for request
  const body: BorrowRequest = {
    employeeId: requestState.employee?.id,
    borrowingLocationId: requestState.location.id,
    startDate: dayjs(requestState.startDate).format('YYYY-MM-DD'),
    endDate: dayjs(requestState.endDate).format('YYYY-MM-DD'),
    ...mapSharedState(requestState),
  }
  const { data } = await api.post<BorrowResult>(`borrow/submit`, {
    data: body,
  })
  return data
}

type RehireResult = APIResult<RehireRequest & RequestResult>
export const postRehire: MutationFunction<
  RehireResult,
  Required<RehireState>
> = async (requestState) => {
  // transform data for request
  const body: RehireRequest = {
    employeeId: requestState.employee?.id,
    destinationLocationId: requestState.location.id,
    isReinstatement: requestState.isReinstatement as boolean,
    ssn: requestState.ssn,
    effectiveDate: dayjs(requestState.effectiveDate as Date).format(
      'YYYY-MM-DD'
    ),
    rate: requestState.titleRate,
    rateUnit: requestState.newRateUnit,
    jobTitleCode: requestState.newTitle.code,
    ...mapSharedState(requestState),
  }
  const { data } = await api.post<RehireResult>(`rehire/submit`, {
    data: body,
  })
  return data
}

type POSJobCodeResult = APIResult<POSJobCodeRequest & RequestResult>
export const postPOSJobCode: MutationFunction<
  POSJobCodeResult,
  Required<POSJobCodeState>
> = async (requestState) => {
  // transform data for request
  const body: POSJobCodeRequest = {
    employeeId: requestState.employee?.id,
    alohaJobCodeForEASIId: requestState.posJobCode?.id,
    ...mapSharedState(requestState),
  }
  const { data } = await api.post<POSJobCodeResult>(`pos/pos-job-submit`, {
    data: body,
  })
  return data
}
type TransferResult = APIResult<TransferRequest & RequestResult>
export const postTransfer: MutationFunction<
  TransferResult,
  Required<TransferState>
> = async (requestState) => {
  // transform data for request
  const body: TransferRequest = {
    employeeId: requestState.employee.id,
    effectiveDate: dayjs(requestState.effectiveDate as Date).format(
      'YYYY-MM-DD'
    ),
    transferToLocationId: requestState.location.id,
    ...(requestState.employee?.hasReportTos && {
      replacementEmployeeId: requestState.replacement.id,
    }),
    ...mapSharedState(requestState),
  }
  const { data } = await api.post<TransferResult>(`transfer/submit`, {
    data: body,
  })
  return data
}

/**
 * LOCATION
 */
export const searchTransferLocation = async (fromLocationId: number) => {
  const { data } = await api.get<APIResult<Array<TransferLocation>>>(
    `transfer/locations-search?fromLocationId=${fromLocationId}`
  )
  return data
}

export const searchBorrowLocation = async (
  q: string,
  fromLocationId: number
) => {
  const { data } = await api.get<APIResult<Array<Location>>>(
    `borrow/locations-search?q=${q}&fromLocationId=${fromLocationId}`
  )
  return data
}

export const searchDestinationLocation = async (q: string) => {
  const { data } = await api.get<APIResult<Array<Location>>>(
    `rehire/locations-search?q=${q}`
  )
  return data
}

/**
 * Termination
 */
export const searchReplacementEmployees = async (
  q: string,
  replacementFor?: number
) => {
  if (!replacementFor) {
    throw new Error('replacementFor is null')
  }
  const query = q || '*'
  const { data } = await api.get<APIResult<Array<EmployeeSearch>>>(
    `replacement-search?q=${query}&replacementFor=${replacementFor}`
  )
  return data
}

/**
 * Job title
 */
export const postJobFunctionChange: MutationFunction<
  APIResult<RequestResult>,
  Required<JobFunctionChangeState>
> = async (state) => {
  const body: JobFunctionChangeRequest = {
    employeeId: state.employee.id,
    adpJobTitleCode: state.title.code,
    effectiveDate: dayjs(state.effectiveDate as Date).format('YYYY-MM-DD'),
    rate: state.rate,
    rateUnit: state.rateUnit,
    reasonCode: state?.reason?.code ?? 'JFC',
    jobFunctions: [
      {
        rate: state.rate,
        rateUnit: state.rateUnit as RateUnit,
        jobCode: state.newJobFunction?.jobCode,
        isPrimary: true,
      },
    ],
    ...mapSharedState(state),
  }

  const { data } = await api.post<APIResult<RequestResult>>(
    '/job-change/submit',
    { data: body }
  )
  return data
}

export const postJobTitleChange: MutationFunction<
  APIResult<RequestResult>,
  Required<JobTitleChangeState>
> = async ({
  title,
  rate,
  rateUnit,
  effectiveDate,
  notes,
  reason,
  userAcknowledgedPendingRequestsInAdp,
  employee,
}) => {
  const body: JobTitleChangeRequest = {
    employeeId: employee.id,
    adpJobTitleCode: title.code,
    effectiveDate: dayjs(effectiveDate as Date).format('YYYY-MM-DD'),
    rate,
    rateUnit,
    notes,
    reasonCode: reason.code,
    userAcknowledgedPendingRequestsInAdp: userAcknowledgedPendingRequestsInAdp,
    pendingRequestsInAdpOnSubmit: employee.employeePendingActionsInAdp,
  }

  const { data } = await api.post('/job-change/submit', { data: body })
  return data
}

export const postImpersonate: MutationFunction<
  APIResult<{ data: string }>,
  string
> = async (Id) => {
  return api.post('admin/impersonate', { Id })
}

type ReturnFromLeaveResult = APIResult<ReturnFromLeaveRequest & RequestResult>
export const postReturnFromLeave: MutationFunction<
  ReturnFromLeaveResult,
  Required<ReturnFromLeaveState>
> = async (rcState) => {
  // transform data for request
  const body: ReturnFromLeaveRequest = {
    employeeId: rcState.employee.id,
    effectiveDate: dayjs(rcState.effectiveDate as Date).format(
      DATE_FORMAT_SERVER // TODO: Migrate other date formats to this if we proceed
    ),
    userAcknowledgedMustSendDoc: rcState.userAcknowledgedMustSendDoc,
    ...mapSharedState(rcState),
  }
  const { data } = await api.post<ReturnFromLeaveResult>(
    `return-from-leave/submit`,
    {
      data: body,
    }
  )
  return data
}

/**
 * File upload
 */
export const getUploadedFiles = async (requestId: number) => {
  if (!requestId) {
    throw new Error('requestId is null')
  }
  const { data } = await api.get<{
    data: Array<UploadedFile>
    meta: { folderUrl: string }
  }>(`requests/${requestId}/uploads`)
  return data
}

export const getFile = async (
  requestId: number,
  fileId: string
): Promise<{ url: string; contentType: string }> => {
  if (!requestId) {
    throw new Error('requestId is null')
  }
  if (!fileId) {
    throw new Error('fileId is null')
  }

  const { data, headers } = await api.get(
    `requests/${requestId}/uploads/${fileId}`,
    {
      responseType: 'blob',
    }
  )
  const url = window.URL.createObjectURL(new Blob([data]))
  return { url, contentType: headers['content-type'] }
}

export interface PostFileUploadArgs {
  requestId: number
  files: File[]
}

export const postRecordRequestFileUpload: MutationFunction<
  APIResult<UploadResult[]>,
  PostFileUploadArgs
> = async ({ requestId, files }) => {
  const formData = new FormData()
  files.forEach((file) => formData.append('files', file))

  return postFileUpload(`requests/${requestId}/uploads`, formData)
}

export interface PostRosterFileUploadArgs {
  file: File
  brand: string | undefined
  lastProcessedCheckDate: Dayjs | null
}

export interface RosterFileUploadResult {
  error: string
  warnings: string[]
}

export const postRosterFileUpload: MutationFunction<
  APIResult<APIResult<RosterFileUploadResult>>,
  PostRosterFileUploadArgs
> = async (args) => {
  const formData = new FormData()
  formData.append('file', args.file)

  let url = `employees/adp-roster-file-upload`

  if (args.lastProcessedCheckDate) {
    const date = args.lastProcessedCheckDate.format('YYYY-MM-DD')
    url = url + `?brand=${args.brand}&lastProcessedCheckDate=${date}`
  }

  return postFileUpload(url, formData)
}

export interface FileUploadArg {
  files: File[]
}

export interface FileUploadResult {
  files: any[]
  error: string
  warnings: string[]
}

export const postTalentReefFileUpload: MutationFunction<
  APIResult<APIResult<FileUploadResult>>,
  FileUploadArg
> = async ({ files }) => {
  const formData = new FormData()
  files.forEach((file) => formData.append('files', file))

  return postFileUpload(`employees/talent-reef-file-upload`, formData)
}

interface DeleteFileArgs {
  requestId: number
  fileId: string
}

export const deleteUploadedFile: MutationFunction<
  APIResult<UploadResult[]>,
  DeleteFileArgs
> = async ({ requestId, fileId }) =>
  api.delete(`requests/${requestId}/uploads/${fileId}`)

export const getUploadConfig = async () =>
  api.get<APIResult<UploadConfig>>('config-upload')

interface StoreAlignmentChangeArgs {
  changesToMake: AlignmentChange[]
  effectiveDate: Date | null
  franchisorAbbr: string
}
type StoreAlignmentChangeResult = APIResult<string>
export const postStoreAlignmentChange: MutationFunction<
  StoreAlignmentChangeResult,
  StoreAlignmentChangeArgs
> = async (changes) => {
  const { data } = await api.post<StoreAlignmentChangeResult>(
    `store-alignment/update`,
    {
      data: {
        ...changes,
        effectiveDate: dayjs(changes.effectiveDate).format('YYYY-MM-DD'),
      },
    }
  )
  return data
}

export const getStoreAlignment = async (
  franchisorAbbr?: string | null,
  effectiveDate?: Date | null
) => {
  if (!franchisorAbbr || !effectiveDate) return null
  const Date = dayjs(effectiveDate).format('YYYY-MM-DD')
  const { data } = await api.get<APIResult<StoreAlignments>>(
    `store-alignment?franchisorAbbr=${franchisorAbbr}&effectiveDate=${Date}`
  )
  return data
}

export const getStoreAlignmentDetail = async (locationId: number) => {
  const { data } = await api.get<APIResult<MgmtEmployee[]>>(
    `store-alignment/detail?locationId=${locationId}`
  )
  return data
}

export const getStoreAlignmentLeaderEmployees = async (effectiveDate: Date) => {
  const Date = dayjs(effectiveDate).format('YYYY-MM-DD')
  const { data } = await api.get<APIResult<LeaderEmployee[]>>(
    `store-alignment/leader-employees?effectiveDate=${Date}`
  )
  return data
}

export const postDennysSales: MutationFunction<
  APIResult<{ data: string }>
> = async () => {
  const body = { auth: 'user' }
  return api.post('accounting/dennys-sales-email', { data: body })
}

export const postShamrockInvoice = async ({ files }) => {
  const formData = new FormData()
  files.forEach((file) => formData.append('files', file))

  return postFileUpload('accounting/shamrock-xml-csv', formData)
}

export async function getTimePunchCoDeptCollections(
  reportEndpointType?: ReportEndpointTypes,
  franchisorAbbrs?: string[]
) {
  let query = `timepunch/codeptcollections?reportEndpointType=${reportEndpointType}`
  if (franchisorAbbrs)
    query += franchisorAbbrs.map((v) => `&franchisorAbbrs=${v}`).join('')

  const { data } = await api.get<APIResult<CoDeptCollection[]>>(query)
  return data
}

export async function getDocumentSignCoDeptCollections() {
  const query = `document-sign/codeptcollections`

  const { data } = await api.get<APIResult<CoDeptCollection[]>>(query)
  return data
}

export async function getTimePunchSelectableEndDates() {
  const { data } = await api.get<APIResult<string[]>>(`timepunch/enddates`)
  return data
}

export async function getPOSJobCodesByEmployeeId(
  employeeId: number | undefined
) {
  const queryEndpoint = `pos/job-codes-by-ee-id/${employeeId}`
  const { data } = await api.get<APIResult<AlohaPosJobCode[]>>(queryEndpoint)
  return data
}

function buildTimePunchQueryEndpoint(
  baseEndpoint: string,
  reportEndpointType?: ReportEndpointTypes,
  startDate?: string,
  endDate?: string,
  coDeptCollectionId?: number,
  franchisorAbbrs?: string[]
): string {
  let queryEndpoint = `${baseEndpoint}?`
  queryEndpoint += reportEndpointType ? `&type=${reportEndpointType}` : ''
  queryEndpoint += startDate ? `&startDate=${startDate}` : ''
  queryEndpoint += endDate ? `&endDate=${endDate}` : ''
  queryEndpoint += coDeptCollectionId
    ? `&coDeptCollectionId=${coDeptCollectionId}`
    : ''
  queryEndpoint += franchisorAbbrs
    ? franchisorAbbrs.map((v) => `&franchisorAbbrs=${v}`).join('')
    : ''

  return queryEndpoint
}

export async function getEmployeeListByTimePunch(
  reportEndpointType?: ReportEndpointTypes,
  startDate?: string,
  endDate?: string,
  coDeptCollectionId?: number,
  franchisorAbbrs?: string[]
) {
  const queryEndpoint = buildTimePunchQueryEndpoint(
    'timepunch/employee-list',
    reportEndpointType,
    startDate,
    endDate,
    coDeptCollectionId,
    franchisorAbbrs
  )

  const { data } = await api.get<APIResult<StoreEmployee[]>>(queryEndpoint)
  return data
}

export async function getAdpJobCodeListByTimePunch(
  reportEndpointType?: ReportEndpointTypes,
  startDate?: string,
  endDate?: string,
  coDeptCollectionId?: number,
  franchisorAbbrs?: string[]
) {
  const queryEndpoint = buildTimePunchQueryEndpoint(
    'timepunch/adp-job-code-list',
    reportEndpointType,
    startDate,
    endDate,
    coDeptCollectionId,
    franchisorAbbrs
  )

  const { data } = await api.get<APIResult<AdpJobCode[]>>(queryEndpoint)
  return data
}

export async function getTimePunchMissingMapping(
  startDate?: string,
  endDate?: string,
  coDeptCollectionId?: number,
  positionId?: string,
  filter?: string,
  franchisorAbbr?: string
) {
  const params = new URLSearchParams(
    stripUndefinedParams({
      startDate,
      endDate,
      coDeptCollectionId: coDeptCollectionId?.toString(),
      positionId,
      filter,
      franchisorAbbr,
    })
  )

  const { data } = await api.get<
    APIResult<{
      areThereMissingMappingsToFixInV3: boolean
      missingMappings: PosToWksMapping[]
    }>
  >(`timepunch/missing-mapping?${params.toString()}`)
  return data
}

export interface IsReinstatement {
  employeeId: number
  isReinstatement: boolean
}

export async function getIsReinstatement(
  employeeId: number,
  effectiveDate: Date,
  newCompanyCode: string
) {
  const params = new URLSearchParams(
    stripUndefinedParams({
      employeeId: employeeId.toString(),
      effectiveDate: effectiveDate.toString(),
      newCompanyCode,
    })
  )

  const { data } = await api.get<APIResult<IsReinstatement>>(
    `rehire/is-reinstatement?${params.toString()}`
  )
  return data
}

export async function getLegalLastName(
  employeeId: number,
  effectiveDate: Date,
  newCompanyCode: string
) {
  const params = new URLSearchParams(
    stripUndefinedParams({
      employeeId: employeeId.toString(),
      effectiveDate: effectiveDate.toString(),
      newCompanyCode,
    })
  )

  const { data } = await api.get<APIResult<IsReinstatement>>(
    `rehire/is-reinstatement?${params.toString()}`
  )
  return data
}

export async function getPosToWksMapping(
  filter?: string,
  store?: number,
  franchisorAbbr?: string
) {
  const params = new URLSearchParams(
    stripUndefinedParams({
      filter,
      storeNumber: store ? `${store}` : undefined,
      franchisorAbbrs: franchisorAbbr,
    })
  )

  const { data } = await api.get<APIResult<PosToWksMapping[]>>(
    `payroll/pos-to-wks-mapping?${params.toString()}`
  )
  return data
}

export async function getTimePunchReportData(
  timePunchUIFilter: ITimePunchUIFilter,
  page?: number,
  perPage?: number
) {
  const queryEndpoint = `timepunch/report?page=${page ?? 1}&perPage=${
    perPage ?? 10
  }`
  const filterBody = createTimePunchFilterBody(timePunchUIFilter)
  if (!filterBody) return null

  const { data } = await api.post<APIResult<any, any>>(
    queryEndpoint,
    filterBody
  )
  return data
}

export function createTimePunchFilterBody(
  timePunchUIFilter: ITimePunchUIFilter
) {
  if (!timePunchUIFilter?.reportEndpointType) return null

  const filterBody = {
    ...timePunchUIFilter,
    reportEndpointType: timePunchUIFilter.reportEndpointType,
    employeesByPositionIds: timePunchUIFilter.employees?.length
      ? timePunchUIFilter.employees?.map((v) => v.adpPositionId)
      : undefined,
    employees: undefined,
    coDeptCollectionId: timePunchUIFilter.coDeptCollection?.id,
    coDeptCollection: undefined,
    adpJobCodes: timePunchUIFilter.adpJobCodes?.length
      ? timePunchUIFilter.adpJobCodes?.map((v) => v.code)
      : undefined,
  }
  return filterBody
}

export const postFileUpload: any = (url: string, formData: FormData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  return api.post(url, formData, config)
}

export const postTimepunchAuditUpload = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  const { data } = await postFileUpload(`timepunch/audit`, formData)
  return data
}

export interface TimePunchExternalImportUploadArgs {
  file: File
  weekEndDate: Dayjs
}

export const postTimepunchExternalImportUpload = async ({
  file,
  weekEndDate,
}: TimePunchExternalImportUploadArgs) => {
  const formData = new FormData()
  formData.append('file', file)

  const { data } = await postFileUpload(
    `timepunch/external-imports?weekEndDate=${weekEndDate.format(
      'YYYY-MM-DD'
    )}`,
    formData
  )
  return data
}

export async function postTimepunchADPExport(
  timePunchUIFilter: ITimePunchUIFilter,
  forceRefreshData: boolean
) {
  const queryEndpoint = `timepunch/adp-export?forceRefreshData=${forceRefreshData}`
  const filterBody = createTimePunchFilterBody(timePunchUIFilter)
  const { data } = await api.post<APIResult<TimePunchAdpExportResult>>(
    queryEndpoint,
    filterBody
  )
  return data
}

export interface IAPIResultError {
  id: string
  createdDateTimeUtc: Date
  errors: IApiError[]
}

export interface IApiError {
  detail: string
}

export const postZoomEmergencyAddressesSync = async () => {
  const body = { auth: 'user' }
  const { data } = await api.post(`zoom/sync`, {
    data: body,
  })

  return data
}

export async function generateBlazeTipReport(payPeriodDate: Dayjs) {
  const queryEndpoint = `payroll/blaze-tip-report?payPeriodDate=${payPeriodDate.format(
    'YYYY-MM-DD'
  )}`
  const { data } = await api.post<APIResult<string>>(queryEndpoint)
  return data
}

export const postGoogleDirectory = async () => {
  const body = { auth: 'user' }
  const { data } = await api.post(`google/directorysync`, {
    data: body,
  })

  return data
}

export async function postPosToAdpUpdate(
  franchisorAbbrFKStoreIDEmployeeNumber: string,
  positionID: string,
  effectiveDate: Dayjs
) {
  const params = new URLSearchParams(
    stripUndefinedParams({
      franchisorAbbrFKStoreIDEmployeeNumber,
      positionID,
      effectiveDate: effectiveDate.format('YYYY-MM-DD'),
    })
  )

  const queryEndpoint = `pos-to-adp/update?${params.toString()}`
  const { data } = await api.post<APIResult<PosToWksMapping[]>>(queryEndpoint)
  return data
}

export async function postPosToAdpDelete(id: number) {
  const queryEndpoint = `pos-to-adp/delete?id=${id}`

  const { data } = await api.post<APIResult<PosToWksMapping[]>>(queryEndpoint)
  return data
}

export async function postAdpPdfProcessor(
  search: string | null,
  company: string | null,
  years: string[] | null,
  includeCheckVouchers: boolean,
  includeW2s: boolean
) {
  let queryEndpoint = `payroll/adp-pdf-processor?search=${search}&company=${company}&includeCheckVouchers=${includeCheckVouchers}&includeW2s=${includeW2s}`
  years && years.forEach((v) => (queryEndpoint += `&years=${v}`))

  const { data } = await api.post<APIResult<string>>(queryEndpoint)
  return data
}

export async function getTimePunchEditLocations(franchisorAbbr: string) {
  const { data } = await api.get<APIResult<Location[]>>(
    `timepunch/edit/locations?franchisorAbbr=${franchisorAbbr}`
  )
  return data
}

export async function postTimePunchEditUpdate(
  punches: TimePunchEdit[],
  employeePositionId: string,
  fiscalWeekEnding: string,
  franchisorAbbr: string
) {
  const body: UpdateTimePunchLockedRequest = {
    punches,
    employeePositionId,
    fiscalWeekEnding,
    franchisorAbbr,
  }
  const { data } = await api.post(`timepunch/edit/update`, body)

  return data
}

export async function postTimePunchEditsValidation(
  punches: TimePunchEdit[],
  employeePositionId: string,
  fiscalWeekEnding: string,
  franchisorAbbr: string
) {
  const body: UpdateTimePunchLockedRequest = {
    punches,
    employeePositionId,
    fiscalWeekEnding,
    franchisorAbbr,
  }
  const { data } = await api.post(`timepunch/edit/validate`, body)

  const validationErrors: TimePunchValidationErrors = data.data
  return validationErrors
}

export async function startEmailVerification(email: string, language: string) {
  const { data } = await api.post<APIResult<StartEmailVerificationResult>>(
    'document-sign/start-email-verification',
    { email, language }
  )
  return data.data
}

export async function completeEmailVerification(
  email: string,
  emailVerificationCode: string
) {
  const { data } = await api.post<APIResult<{ isCodeCorrect: boolean }>>(
    'document-sign/complete-email-verification',
    { emailVerificationCode, email }
  )
  return data.data.isCodeCorrect
}

export async function confirmSsnLastFour(request: ConfirmSsnLastFourRequest) {
  const { data } = await api.post<APIResult<ConfirmSsnLastFourResult>>(
    'document-sign/confirm-ssn-last-four',
    request
  )
  return data.data
}

export async function generateDocuments(request: GenerateDocumentsRequest) {
  const { data } = await api.post<APIResult<GenerateDocumentsResult>>(
    'document-sign/generate-documents',
    request
  )
  return data.data
}

export async function createDocumentLink(request: DocumentLinkRequest) {
  const { data } = await api.post<APIResult<{ documentLink: string }>>(
    `document-sign/document-link`,
    request
  )
  return data.data.documentLink
}

export async function getCompletedDocumentLink(request: DocumentLinkRequest) {
  const { data } = await api.post<APIResult<{ documentLink: string }>>(
    `document-sign/completed-document-link`,
    request
  )
  return data.data.documentLink
}

export async function submitParentOrGuardianMobile(
  request: ParentOrGuardianMobileRequest
) {
  const { data } = await api.post<APIResult<{ success: boolean }>>(
    `document-sign/parent-or-guardian-mobile`,
    request
  )
  return data.data.success
}

export async function validateDocumentSignEmail(request: ValidateEmailRequest) {
  const { data } = await api.post<APIResult<ValidateEmailResult>>(
    `document-sign/validate-email`,
    request
  )
  return data.data
}

export async function submitParentOrGuardianInformation(
  request: ParentOrGuardianRequest
) {
  const { data } = await api.post<APIResult<{ success: boolean }>>(
    `document-sign/parent-or-guardian`,
    request
  )
  return data.data.success
}

export async function getEsignMainDashboard(
  coDeptCollectionId: number | undefined
) {
  const uri = coDeptCollectionId
    ? `document-sign/dashboard?coDeptCollectionId=${coDeptCollectionId}`
    : `document-sign/dashboard`
  const { data } = await api.get<APIResult<EsignMainDashboardData>>(uri)
  return data
}

export async function getEsignDetail({
  documentId,
  employeeId,
}: {
  documentId?: string
  employeeId?: number
}) {
  const body = {
    documentId,
    employeeId,
  }
  const { data } = await api.post<APIResult<EsignDetailData>>(
    `document-sign/detail`,
    body
  )
  return data
}

export async function downloadFileFromApi(
  apiEndpoint: string,
  requestData: any,
  fileName: string
) {
  const { data, headers } = await api.post(apiEndpoint, requestData, {
    responseType: 'blob',
  })

  const url = window.URL.createObjectURL(
    new Blob([data], { type: headers['content-type'] })
  )

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

export async function getEmployeeDoc(request: EmployeeDocRequest) {
  const { data } = await api.post<APIResult<EsignEmployeeDocData[]>>(
    `document-sign/employee-docs`,
    request
  )
  return data.data
}

export async function getKkdKorberToSageMappings() {
  const { data } = await api.get<APIResult<KkdKorberToSageListResponse>>(
    `accounting/kkd-korber-to-sage-list`
  )
  return data.data
}

export async function addKkdKorberToSageMapping(
  request: KkdKorberToSageMapData
) {
  const { data } = await api.post<APIResult<KkdKorberToSageMapData>>(
    `accounting/kkd-korber-to-sage-list/add-map`,
    request
  )
  return data.data
}

export async function updateKkdKorberToSageMapping(
  request: KkdKorberToSageMapData
) {
  const { data } = await api.post<APIResult<KkdKorberToSageMapData>>(
    `accounting/kkd-korber-to-sage-list/update-map`,
    request
  )
  return data.data
}

export async function deleteKkdKorberToSageMapping(id: number) {
  const { data } = await api.post(
    `accounting/kkd-korber-to-sage-list/delete-map/${id}`
  )
  return data.data
}

export const postDmaDeliversInvoice = async ({ files }) => {
  const formData = new FormData()
  files.forEach((file) => formData.append('files', file))

  return postFileUpload('accounting/dma-delivers/csv', formData)
}

export async function getDmaDeliversMappingList() {
  const { data } = await api.get<APIResult<EplToSageAccount[]>>(
    `accounting/dma-delivers/mapping-list`
  )
  return data.data
}

function buildDmaRequest(row: EplToSageAccountRow) {
  const request: EplToSageAccount = {
    sageAcct: row.sageAcct,
    sageAcctDescription: row.sageAcctDescription,
  }
  if (row.newMappingType === brandItemGlCode) {
    request.brandItemGlCode = row.newMappingValue
    request.brandItemGlCodeDescription = row.newMappingDescription
  } else if (row.newMappingType === dcItemNumber) {
    request.dcItemNumber = row.newMappingValue
    request.dcItemNumberDescription = row.newMappingDescription
  }
  return request
}

export async function addDmaDeliversMapping(row: EplToSageAccountRow) {
  const request = buildDmaRequest(row)

  const { data } = await api.post<APIResult<EplToSageAccount>>(
    `accounting/dma-delivers/mapping-list/add`,
    request
  )
  return data.data
}

export async function updateDmaDeliversMapping(row: EplToSageAccountRow) {
  const request = buildDmaRequest(row)
  request.id = row.id

  const { data } = await api.post<APIResult<EplToSageAccount>>(
    `accounting/dma-delivers/mapping-list/update`,
    request
  )
  return data.data
}

export async function deleteDmaDeliversMapping(id: number) {
  const { data } = await api.post(
    `accounting/dma-delivers/mapping-list/delete/${id}`
  )
  return data.data
}

export async function syncDocumentStatus(request: SyncDocumentStatusRequest) {
  const { data } = await api.post<APIResult<SyncDocumentStatusResult>>(
    `document-sign/sync-document-status`,
    request
  )
  return data.data?.documentStatus
}

export const postKkdKorberInvoices: MutationFunction<
  APIResult<{ data: string }>
> = async () => {
  const body = { auth: 'user' }
  return api.post('accounting/kkd-korber-invoices-download', { data: body })
}

export async function documentSignLogout(request: DocumentSignLogoutRequest) {
  await api.post<APIResult<void>>(`document-sign/logout`, request)
}

export function documentSignHealthCheck() {
  return api.get<APIResult<{ isResponsive: boolean }>>(
    `document-sign/health-check`
  )
}

export function logoutAsync() {
  return api.post<APIResult<void>>(`logout-async`)
}

export async function syncEmployeeFromADP(request: SyncEmployeeFromADPRequest) {
  const { data } = await api.post<APIResult<SyncEmployeeInfo>>(
    `employees/sync-adp`,
    request
  )
  return data.data
}
